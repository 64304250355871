import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, colors, Box, Button } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  }
}));

const data = [
  {
    icon: 'fas fa-user',
    title: 'Contacts',
    // subtitle:
    //   <span>Call, message or WhatsApp anyone involved in the event without having to save every new provider’s number on each event.</span>
    subtitle:
      <span>Reach anyone on your event with a single tap (phone,WhatsApp,mail,…)</span>
  },
  {
    icon: 'fas fa-map-pin',
    title: 'Locations',
  //   subtitle:
  // <span>Get the direction to all event locations on your favorite GPS application in a single tap.</span>
    subtitle:
      <span>Get directions to all event locations instantly (maps,Waze,coordinate,…)
</span>
  },
  {
    icon: 'fas fa-clock',
    title: 'Timeline',
  //   subtitle:
  // <span>Never lose the thread of your event with a timeline regrouping all key moments. Set a notification to make sure you don’t miss important timings</span>
    subtitle:
      <span>Never lose the thread of your event with a timeline regrouping all key moments (reminders,notifications,…)</span>
  },
];

const FastCheckIn = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        subtitle={<Box mt={5} style={{ textWrap: 'balance' }}><span className={classes.textWhite}><span className={classes.textOrange}>Qeerio</span> helps you <span className={classes.textOrange}>organize</span> your event informations with different <span className={classes.textOrange}>sections</span> such as:</span></Box>}
        titleVariant="h3"
        subtitleVariant='h4'
        title={<div><div className={classes.textWhite}>How to <span className={classes.textOrange}>structure</span> your event <span className={classes.textOrange}>informations</span>?</div></div>}
        ctaGroup={[
          <Box
            mt={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container spacing={isMd ? 4 : 7}>
              {data.map((item, index) => (
                <Grid key={index} item xs={12} sm={4} data-aos={'fade-up'}>
                  <DescriptionListIcon
                    title={item.title}
                    subtitle={item.subtitle}
                    icon={
                      <IconAlternate
                        fontIconClass={item.icon}
                        size="medium"
                        color={colors.indigo}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
            <Box mt={4}>
              <Button variant="outlined" color="secondary" size="large" href="/productionbook/sections" aria-label="Explore all sections">
                Explore all sections
              </Button>
            </Box>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

FastCheckIn.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default FastCheckIn;
