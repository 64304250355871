import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(() => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase',
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase',
  },
}));

const FirstImpression = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        // subtitle={<div className={classes.textWhite}><div>A<span className={classes.textOrange}> special</span> app for a <span className={classes.textOrange}> special </span>team</div>
        //   {/* <Box mt={4}>Make your team stronger on site with <span className={classes.textOrange}>Qeerio</span></Box> */}
        // </div>}
        titleVariant="h3"
        title={<Box mt={3} className={classes.textWhite}>All key informations <span className={classes.textOrange}>in a single app </span>!</Box>}
        ctaGroup={[
          <Box mt={6}>
            <Button variant="outlined" color="secondary" size="large" href="https://productionbook-backoffice.qeerio.com" aria-label="Get started now">
            Get started now
            </Button>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

FirstImpression.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default FirstImpression;
