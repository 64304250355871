import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Grid, Box, IconButton } from '@material-ui/core';
import { IconAlternate } from 'components/molecules';
import { CardReviewSarah, CardReviewAnnabelle, CardReviewSeb } from 'components/organisms/productionbook';
import { StaticImage } from 'gatsby-plugin-image';
import { Icon } from 'components/atoms';
import Swipe from 'swipejs';
import './review.css';

// const useStyles = makeStyles(() => ({
// }));

const Reviews = props => {
  const { data, className, ...rest } = props;
  // const classes = useStyles();

  React.useEffect(() => {
    var element = document.getElementById('slider');
    window.slider = new Swipe(element, {
      startSlide: 0,
      auto: 1000000,
      draggable: false,
      autoRestart: false,
      continuous: true,
      disableScroll: false,
      stopPropagation: true,
      callback: function (index, element) { },
      transitionEnd: function (index, element) { }
    });
  });

  return (
    <>
      <div className={className} data-aos="fade-up" {...rest} style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <Box mr={4}>
          <IconButton
            aria-label="previous review"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => window.slider.prev()}>
            <Icon
              fontIconClass='fas fa-chevron-left'
            />
          </IconButton>
        </Box>
        <div id="slider" class="swipe">
          <div class="swipe-wrap">
            <div><CardReviewSarah
              style={{
                margin: 'auto'
              }}
              key={'annabelle'}
              className={'swiper-slide'}
              noBorder
              noShadow
              text="We use Qeerio Production book on every single event. Every provider has a clear view on his part of the event. This saves everybody a lot of time we generally don’t have during an event."
              // text='The time saved before and after the event for the check-in management is insane. The time saved, the affordable prices and guest satisfaction makes it a no brainer.'
              icon={
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="fas fa-quote-right"
                />
              }
              authorName='Sarah Hammond'
              authorTitle='Seventry, Event operations manager'
              authorPhoto={null}
            /></div>
            <div>
              <CardReviewSeb
                style={{
                  margin: 'auto'
                }}
                key={'charleslouis'}
                className={'swiper-slide'}
                noBorder
                noShadow
                text="With Qeerio it's easy to find the right person to call when there is a question about anything. Qeerio has become THE everyday app of our event managers."
                icon={
                  <IconAlternate
                    color={colors.indigo}
                    fontIconClass="fas fa-quote-right"
                  />
                }
                authorName='Sebastián Delgado'
                authorTitle='ETL, Event director'
                authorPhoto={null}
              /></div>
            <div><CardReviewAnnabelle
              style={{
                margin: 'auto'
              }}
              key={'jack'}
              className={'swiper-slide'}
              noBorder
              noShadow
              text='Everything is in the app!  No more printing and carrying all informations on event. All vital event information is on my phone. We also share some key sections with our clients so that they can be reassured and follow how the event is going in real time!'
              icon={
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="fas fa-quote-right"
                />
              }
              authorName='Annabelle Mekari'
              authorTitle='Profirst, Project Coordinator'
              authorPhoto={null}
            /></div>
          </div>
        </div>
        <Box ml={4}>
          <IconButton
            aria-label="next review"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => window.slider.next()}>
            <Icon
              fontIconClass='fas fa-chevron-right'
            />
          </IconButton>
        </Box>
      </div>
      <Box mt={9}>
        <Grid container spacing={7} alignItems='center'>
          <Grid
            item
            xs={4}
            md={2}
          >
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '17px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/bnp_paribas.png'
              alt="Bnp logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '8px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/deutsche_bank.png'
              alt="Deutschebank logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '17px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/profirst.png'
              alt="Profirst logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '14px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/redken.png'
              alt="Redken logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '10px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/dior.png'
              alt="Dior logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '10px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/porsche.png'
              alt="Porsche logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Reviews.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Reviews;
