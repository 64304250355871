import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Box, Button, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  },
  createEvent: {
    width: '90%'
  },
  iphoneImage: {
    width: '110%'
  }
}));

const FastCheckIn = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        // subtitle={<div><span className={classes.textOrange}>Save valuable time</span>{' '}<span className={classes.textWhite}>on your check-in planning</span></div>}
        subtitleVariant="h4"
        titleVariant="h3"
        title={<Box mt={1}><span className={classes.textWhite}>How does it work?</span></Box>}
        ctaGroup={[
          <Box
            mt={10}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container spacing={7} justify="center" alignItems="center">
              <Grid key={1} item xs={12} sm={4} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mb={2}>
                    <Typography variant="h5" color="secondary">
                      <span><b>Create</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary" align="center">
                        Gather and structure all event informations
                      </Typography>
                    </Box>
                  </Box>
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/productionbook/create_event.webp'
                    alt="Mac image"
                    className={classes.createEvent}
                    loading="lazy"
                  />
                </Box>
              </Grid>
              <Grid key={2} item xs={12} sm={4} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mb={2}>
                    <Typography variant="h5" color="secondary">
                      <span><b>Share</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary">
                        Share your event with all key event actors
                      </Typography>
                    </Box>
                  </Box>
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/productionbook/share_event.webp'
                    alt="Phone Image"
                    className={classes.createEvent}
                    loading="lazy"
                  />
                </Box>
              </Grid>
              <Grid key={2} item xs={12} sm={4} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" bt={2}>
                    <Typography variant="h5" color="secondary">
                      <span><b>Enjoy</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary" align="center">
                        Access all key event information one site anytime
                      </Typography>
                    </Box>
                  </Box>
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/productionbook/enjoy_event.webp'
                    alt="Phone Image"
                    className={classes.iphoneImage}
                    loading="lazy"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={10}>
              <Button variant="outlined" color="secondary" size="large" href="https://productionbook-backoffice.qeerio.com" aria-label="Get started now">
                Get started now
              </Button>
            </Box>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

FastCheckIn.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default FastCheckIn;
