/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Seo from '../../seo/productionbook';
import Home from 'views/productionbook/Home';
import Main from 'layouts/Productionbook';
import WithLayout from 'WithLayout';

const ProductionBookPage = () => {
  return (
    <WithLayout
      seo={Seo}
      component={Home}
      layout={Main}
    />
  );
};

export default ProductionBookPage;
