import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const Contact = props => {
  const { className, ...rest } = props;

  return (
    <div className={clsx('jarallax', className)} {...rest}>
      <SectionHeader
        title="Any questions?"
        subtitle="Please leave us a message. We are happy to help make your next event a success!"
        titleVariant='h4'
        subtitleVariant='h5'
        ctaGroup={[
          <Box mt={2}>
            <Button variant="outlined" color="secondary" href="/productionbook/support" aria-label="Contact us">
              Contact us
            </Button>
          </Box>
        ]}
        data-aos="fade-up"
      />
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default Contact;
