import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Box,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(theme => ({
  appStore: {
    maxWidth: 125,
    marginRight: '30px'
  },
  googlePlayBtn: {
    border: '1px solid #A6A6A6',
    borderRadius: '5px',
    maxWidth: '119px',
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  coverImage: {
    height: '700px',
  },
  avatar: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(-2),
    border: `4px solid ${theme.palette.background.paper}`,
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

const Intro = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Grid container spacing={isMd ? 4 : 2}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              xs={12}
              md={6}
              data-aos="fade-up"
            >
              <StaticImage
                src='../../../../../assets/images/qeerio/productionbook/first_image.webp'
                placeholder="blurred"
                loading="lazy"
                className={classes.coverImage}
                alt="Phone image" />
            </Grid>
            <Grid item xs={12} md={6}
              data-aos="fade-up"
            >
              <SectionHeader
                align='center'
                title={
                  <>
                    <Box mb={2}>
                      <StaticImage
                        placeholder="blurred"
                        src='../../../../../assets/images/qeerio/productionbook/logo.webp'
                        alt="Icon productionbook"
                        width={100}
                        height={100}
                      />
                    </Box>
                    <Box mb={2}>
                      <div>
                        <Typography color="textPrimary" variant="h3" component="span">
                          <b>
                            <span>Your <span style={{ color: 'rgb(255, 100, 83)' }}>whole event</span> in the pocket!</span>
                          </b>
                        </Typography>
                      </div>
                    </Box>
                    <Box>
                      <Typography color="textSecondary" variant="h6" component="span">
                        Download the app and get started in seconds!
                      </Typography>
                    </Box>
                  </>
                }
                ctaGroup={[
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <Box mt={6}>
                      <Button variant="outlined" color="secondary" size="large" href="https://productionbook-backoffice.qeerio.com" aria-label="Get started now">
                        Get started now
                      </Button>
                    </Box>
                    <Box
                      mt={4}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }}
                    >
                      <Image
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          window.open('https://apps.apple.com/vn/app/qeerio-production-book/id1571446526?platform=iphone')
                        }}
                        src={require('../../../../../assets/images/qeerio/app-store.webp').default}
                        alt="Get in on App Store"
                        className={classes.appStore}
                        lazy
                      />
                      <Image
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          window.open('https://play.google.com/store/apps/details?id=com.qeerio.productionbook')
                        }}
                        src={require('../../../../../assets/images/qeerio/play-store.webp').default}
                        alt="Get in on Play Market"
                        className={classes.googlePlayBtn}
                        lazy
                      />
                    </Box>
                  </Box>
                ]}
                // subtitle="Download the app and get started in seconds!"
                disableGutter
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Intro;
